import { Box, Flex } from '@rebass/grid'
import { Alert, InputGroup, Nav } from '@tkxs/cast-ui'
import _ from 'lodash'
import * as Moment from 'moment'
import { extendMoment } from 'moment-range'
import { useSession } from 'next-auth/react'
import { useRouter } from 'next/router'
import { useState } from 'react'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import styled from 'styled-components'
import useSWR from 'swr'
import { CTitle } from '../../common/cast-ui-fixes'
import {
  ControlNavbar,
  Loader,
  SessionUser,
  SSelect,
  SSpinner,
} from '../../common/common'
import { getUtilization, getUtilizationColor } from '../../common/helpers'
import { isAdmin, isHr } from '../../utils/auth'
import { camelizeKeys } from '../../utils/converters'

const StyledPage = styled.div`
  width: 100%;
  .page {
  }
`

interface Props {
  pageProps: any
  theme?: any
}

const STable = styled.table`
  width: 100%;
  thead {
    tr {
      th {
        padding: 10px 0;
        min-width: calc(100% / 12) !important;
        // display: inline-block;
        color: #fff;
        background: rgb(31, 78, 119);
        font-weight: 500;
      }
      th:first-child {
        min-width: 200px !important;
        width: 200px !important;
      }
    }
  }
  tbody {
    tr {
      td {
        padding: 3px 0;
        // width:  calc(100%/12) !important;
        text-align: center;
        vertical-align: middle;
      }
    }
  }

  tbody {
    tr {
      td.danger {
        padding: 5px 0;
        background: red;
        color: white;
        font-weight: 600;
      }
    }
  }
`

export function Utilization({ pageProps }: Props) {
  const embed = pageProps?.embed || false
  const team = pageProps?.team
  const month = pageProps?.month
  const moment = extendMoment(Moment)

  const { data: session, status } = useSession()
  const [timesheetType, selectTimesheetType] = useState('report')
  const [timesheetYear, selectTimesheetYear] = useState(moment().format('YYYY'))
  const [timesheetFrequency, selectTimesheetFrequency] = useState('weekly')
  const [employee, selectEmployee] = useState('')

  const sessionUser: SessionUser = session && session?.user

  let filter =
    !isAdmin(session) && !isHr(session)
      ? `&filter[]=${session?.user['profile']?.employeeId}`
      : ''

  let filteredTimesheets = []

  const { data: utilizationAPIData, error: utilizationAPIError } = useSWR([
    process.env.NEXT_PUBLIC_API_URL + '/timesheets/utilization',
    session?.user['accessToken'],
  ])

  let subOrdinates: any[] = sessionUser?.subordinates
    ? _.map(sessionUser.subordinates, 'employeeId')
    : []

  if (sessionUser && sessionUser?.profile && sessionUser?.profile?.employeeId) {
    subOrdinates.push(sessionUser?.profile?.employeeId)
    subOrdinates.reverse()
  }

  if (!isAdmin(session) && !isHr(session) && !team) {
    filter = _.join(
      _.map(subOrdinates, function (subOrdinate) {
        return `&filter[]=${subOrdinate}`
      }),
      '',
    )
  }

  if (team) {
    filter = _.join(
      _.map(team, function (subOrdinate) {
        return `&filter[]=${subOrdinate}`
      }),
      '',
    )
  }

  const { data, error } = useSWR(`/api/cache?cache=timesheets${filter}`)

  const { data: tasksData, error: tasksError } = useSWR(
    `/api/cache?cache=tasks${filter}`,
  )

  if (error && error.response) {
    return isAdmin(session) || isHr(session) ? (
      <Alert alertStyle="danger" lightMode>
        <b>
          HTTP Status {error.response.status} ({error.response.statusText}):
        </b>{' '}
        {error.response.data.message}
      </Alert>
    ) : (
      <Alert alertStyle="danger" lightMode>
        <b>Error:</b> There was an issue retrieving Timesheets. Refresh.
      </Alert>
    )
  }

  if (!data) {
    return (
      <Loader className="-loading -active">
        <SSpinner className="spinner" size={30} />
      </Loader>
    )
  }

  let timesheets = data ? camelizeKeys(data.data) : null

  let loadedTasks = tasksData ? camelizeKeys(tasksData.data) : null

  let utilizationData = []
  timesheets = _.sortBy(timesheets, [
    function (o) {
      return o.attributes.belongsTo?.name
    },
  ])

  // session.user['profile'].employeeId === o.attributes.belongsTo.id || session.user['profile'].employeeId === o.attributes.createdBy.creatorId || session.user['id'] === o.attributes.createdBy.creatorId
  timesheets = _.filter(timesheets, function (o) {
    return (
      o.attributes.sheetType === timesheetType &&
      (!isAdmin(session) && !isHr(session)
        ? _.includes(subOrdinates, o.attributes.belongsTo.id)
        : true)
    )
  })

  loadedTasks = _.filter(loadedTasks, function (o) {
    return !isAdmin(session) && !isHr(session)
      ? _.includes(subOrdinates, o.attributes.assignedTo.employeeId)
      : true
  })

  timesheets = _.filter(timesheets, function (o) {
    return (
      o.attributes.sheetType === timesheetType &&
      (timesheetType == 'report'
        ? o.attributes.sheetFrequency == 'weekly'
        : o.attributes.sheetFrequency == 'monthly')
    )
  })
  timesheets.map((timesheet: any) => {
    if (timesheet.attributes.belongsTo.id) {
      return {
        value: timesheet.attributes.belongsTo.id,
        label: timesheet.attributes.belongsTo.name,
      }
    }
  })

  if (team) {
    timesheets = _.filter(timesheets, function (timesheet) {
      return team.includes(timesheet.attributes.belongsTo.id)
    })
  }

  timesheets = timesheets.map((timesheet: any) => {
    let tasks = []
    _.map(timesheet.attributes.tasks, function (item) {
      let task = _.find(loadedTasks, function (o) {
        return o.id === item
      })

      if (task !== undefined) {
        tasks.push(task)
      }
    })

    let start, end

    if (timesheet.attributes.period) {
      // let m = moment().set({ 'year': timesheet.attributes.period?.year, 'month': timesheet.attributes.period.month }).dayOfYear(timesheet.attributes.period.day);
      let m = moment().set({
        year: timesheet.attributes.period?.year,
        week: timesheet.attributes.period.week,
      })

      if (timesheet.attributes.period.month) {
        m.set('month', timesheet.attributes.period.month)
      }

      if (timesheet.attributes.period.day) {
        m.dayOfYear(timesheet.attributes.period.day)
      }

      switch (timesheet.attributes.sheetFrequency) {
        case 'daily':
          start = m.startOf('day').toDate()
          end = m.endOf('day').toDate()
          break
        case 'weekly':
          start = m.startOf('week').toDate()
          end = m.endOf('week').toDate()
          break
        case 'monthly':
          start = m.startOf('month').toDate()
          end = m.endOf('month').toDate()
          break
        case 'annual':
          start = m.startOf('year').toDate()
          end = m.endOf('year').toDate()
          break
      }
    }

    const total_estimate = _.sumBy(tasks, function (o) {
      let value = o.attributes.timing?.estimate

      if (o.attributes.timing?.estimateUnit == 'hours') {
        value = value * 60
      }

      if (o.attributes.timing?.estimateUnit == 'days') {
        value = value * 8 * 60
      }
      return value || 0
    })

    const total_actual = _.sumBy(tasks, function (o) {
      let value = o.attributes.timing?.actual

      if (o.attributes.timing?.actualUnit == 'hours') {
        value = value * 60
      }

      if (o.attributes.timing?.actualUnit == 'days') {
        value = value * 8 * 60
      }

      return value || 0
    })

    const newUtilization = getUtilizationColor(
      timesheet.attributes.utilization.utilization * 100,
    )
    const oldUtilization = getUtilization(
      timesheet.attributes.sheetType === 'forecast'
        ? total_estimate
        : total_actual ?? 0,
      timesheet.attributes.sheetFrequency,
    )

    const utilization = timesheet.attributes.utilization?.utilization
      ? newUtilization
      : oldUtilization

    timesheet.utilization = utilization
    // timesheet.attributes.tasks = tasks;
    if (timesheet.attributes.period?.year == timesheetYear) {
      utilizationData.push({
        id: timesheet.id,
        start: new Date(start),
        end: new Date(end),
        name: timesheet.attributes.name,
        color: utilization.color,
        type: timesheet.attributes.sheetType,
        year: timesheet.attributes.period?.year,
        frequency: timesheet.attributes.sheetFrequency,
        period: timesheet.attributes.period,
        utilization: utilization,
        employee: timesheet.attributes.belongsTo.id,
        employee_name: timesheet.attributes.belongsTo.name,
        created_at: timesheet.attributes.createdBy.createdAt,
      })
    }
    return timesheet
  })

  let calendar = []
  moment.months().map((e, i) => {
    calendar[i] = {
      month: i + 1,
      month_name: moment().month(i).clone().startOf('month').format('MMM'),
      total_weeks:
        moment().month(i).clone().endOf('month').week() -
        moment().month(i).clone().startOf('month').week(),
      total_days: parseInt(
        moment().month(i).clone().endOf('month').format('D'),
      ),
    }
  })

  const startWeek = month
    ? _.sumBy(calendar, (o) => {
        return o.month < month ? o.total_weeks : 0
      })
    : 1
  if (month) {
    const monthCalendar = calendar[month - 1]
    calendar = []
    calendar.push(monthCalendar)
  }

  let rows = ''
  const users = _.uniqBy(
    timesheets.map((timesheet: any) => {
      if (timesheet.attributes.belongsTo.id) {
        return {
          value: timesheet.attributes.belongsTo.id,
          label: timesheet.attributes.belongsTo.name,
        }
      }
    }),
    'value',
  )

  let selectedEmployee: any = ''
  if (employee) {
    utilizationData = _.filter(utilizationData, ['employee', employee])
    selectedEmployee = _.find(users, ['value', employee]) || ''
  }

  if (session && session.user && embed && !team) {
    const user: any = _.find(users, ['label', session.user['name']])
    if (user && user['value'] !== employee) {
      selectEmployee(user['value'])
    }
  }

  const grouped = _.groupBy(
    _.sortBy(utilizationData, 'employee_name'),
    function (e) {
      return e.employee_name
    },
  )

  _.range(1).map((v, i) => {
    let count = startWeek
    rows += '<tr><td></td>'
    calendar.map((value, index) => {
      _.range(value.total_weeks).map((v, i) => {
        rows += `<td><small></small></td>`
        count++
      })
    })
    rows += '</tr>'
  })

  _.range(1).map((v, i) => {
    let count = startWeek
    rows += '<tr><td style="color: #FFF;"></td>'
    calendar.map((value, index) => {
      _.range(value.total_weeks).map((v, i) => {
        rows += `<td><small style="padding: 5px 0; display: block;">${
          month ? `Week ${count}` : `Wk <br/>${count}`
        }</small></td>`
        count++
      })
    })
    rows += '</tr>'
  })
  let weekCount = startWeek
  _.forEach(Object.entries(grouped), function (vk, vx) {
    let sortedTimesheets = _.sortBy(vk[1], function (t) {
      return t.name
    })
    rows += '<tr>'
    rows += `<td style="text-align: right; padding: 10px 0; display: block;  padding-right: 10px;  background: #FFF; color: #000; min-width: 280px; text-transform: uppercase; border-bottom: 1px solid #ccc;"><small><b>${vk[0]}</b></small></td>`
    if (vk.length > 0) {
      weekCount = startWeek
      calendar.map((value, index) => {
        _.range(value.total_weeks).map((w, i) => {
          const tS = _.find(sortedTimesheets, function (o) {
            return o.period.week == weekCount && o.frequency == 'weekly'
          })
          if (tS != undefined) {
            rows += `<td style="background:${
              tS.color
            } !important;"><Link href="/timesheets/${
              tS.id
            }"><a href="/timesheets/${
              tS.id
            }" target="_blank"><small style="background:${
              tS.color
            } !important;  color: #fff;">${tS.utilization?.value || 0}% ${
              month && tS.utilization?.label ? `(${tS.utilization?.label})` : ''
            }</small><a></Link></td>`
          } else {
            rows += `<td style="background:#ccc !important;"><small style="background:#ccc !important;"></small></td>`
          }
          weekCount++
        })
      })
    }
    rows += '</tr>'
  })

  return (
    <StyledPage>
      {!pageProps?.embed && (
        <ControlNavbar>
          <Nav left>
            <Flex alignItems={'center'}>
              <Box>
                <CTitle size={20}>
                  Resource Utilization{' '}
                  {selectedEmployee && ` - ${selectedEmployee?.label}`}
                </CTitle>
              </Box>
            </Flex>
          </Nav>
          <Nav right>
            <Box ml={6}></Box>
          </Nav>
        </ControlNavbar>
      )}
      {error && (
        <Alert alertStyle="danger" lightMode>
          There was an issue fetching the timesheets. {error?.error}
        </Alert>
      )}
      {!data && (
        <Loader className="-loading -active">
          <SSpinner className="spinner" size={30} />
        </Loader>
      )}
      {!embed && (
        <>
          <Flex width={[1]} justifyContent="right" pr={12}>
            {(isAdmin(session) || isHr(session)) && (
              <Box width={1 / 6} mr={12}>
                <InputGroup label="Employee">
                  <SSelect
                    id="employee"
                    onChange={(value) => {
                      selectEmployee(value.value)
                    }}
                    options={[
                      {
                        value: '',
                        label: 'All',
                      },
                      ...users,
                    ]}
                    isMulti={false}
                    isDisabled={false}
                    isClearable={false}
                    controlSpecificProps={{ isSearchable: false }}
                    highlightFilled={false}
                    style={{ width: '100%', display: 'block' }}
                    placeholder={'Select sheet type'}
                    selectedOption={[
                      {
                        value: '',
                        label: 'All',
                      },
                      ...users,
                    ].find((option) => option['value'] === employee)}
                  />
                </InputGroup>
              </Box>
            )}
            <Box width={1 / 8} mr={12}>
              <InputGroup label="Sheet Type">
                <SSelect
                  id="type"
                  onChange={(value) => {
                    selectTimesheetType(value.value)
                    selectTimesheetFrequency(
                      value.value === 'forecast' ? 'monthly' : 'weekly',
                    )
                  }}
                  options={[
                    { value: 'forecast', label: 'Forecast' },
                    { value: 'report', label: 'Report' },
                  ]}
                  isMulti={false}
                  isDisabled={false}
                  isClearable={false}
                  controlSpecificProps={{ isSearchable: false }}
                  highlightFilled={false}
                  style={{ width: '100%', display: 'block' }}
                  placeholder={'Select sheet type'}
                  selectedOption={[
                    { value: 'forecast', label: 'Forecast' },
                    { value: 'report', label: 'Report' },
                  ].find((option) => option.value === timesheetType)}
                />
              </InputGroup>
            </Box>
            {/* <Box width={1 / 8} mr={12}>
                            <InputGroup label="Sheet Frequency">
                                <span style={{ paddingTop: '12px' }}>{_.startCase(timesheetFrequency)}</span>
                            </InputGroup>
                        </Box> */}
            <Box width={1 / 8}>
              <InputGroup label="Year">
                <SSelect
                  id="year"
                  onChange={(value) => {
                    selectTimesheetYear(value.value)
                  }}
                  options={[
                    { value: '2021', label: '2021' },
                    { value: '2022', label: '2022' },
                  ]}
                  isMulti={false}
                  isDisabled={false}
                  isClearable={false}
                  controlSpecificProps={{ isSearchable: false }}
                  highlightFilled={false}
                  style={{ width: '100%', display: 'block' }}
                  placeholder={'Select year'}
                  selectedOption={[
                    { value: '2021', label: '2021' },
                    { value: '2022', label: '2022' },
                  ].find((option) => option.value === timesheetYear)}
                />
              </InputGroup>
            </Box>
          </Flex>
          <br />
        </>
      )}
      <div style={{ overflow: 'auto' }}>
        <STable className="table">
          <thead>
            <tr>
              <th key={0} style={{ background: '#aa1e2be0' }}>
                {month ? 'Team Member' : 'Timesheets'}
              </th>
              {calendar.map((e, i) => {
                return (
                  <th key={i} colSpan={Math.ceil(e.total_weeks)}>
                    {month
                      ? `${moment()
                          .month(e.month_name)
                          .format('MMMM')} Utilization`
                      : e.month_name}
                  </th>
                )
              })}
            </tr>
          </thead>
          {loadedTasks && loadedTasks.length > 0 ? (
            <tbody dangerouslySetInnerHTML={{ __html: rows }}></tbody>
          ) : (
            <tbody>
              <tr>
                <td colSpan={52}>
                  <Loader className="-loading -active">
                    <SSpinner className="spinner" size={30} />
                  </Loader>
                </td>
              </tr>
            </tbody>
          )}
        </STable>
      </div>
      {Object.entries(grouped).length < 1 && (
        <Alert alertStyle="warning" lightMode>
          No Timesheets available
        </Alert>
      )}
    </StyledPage>
  )
}

export default Utilization
