import { Display, Title } from '@tkxs/cast-ui'
import styled from 'styled-components'

export const CTitle = styled(Title)`
  font-family: Roboto, 'IBM Plex Sans', 'Libre Franklin', arial, sans-serif;
  color: #303e47;
  margin: 1em 0 0;
  display: inline-block;
  &.lg {
    font-size: 40px;
    margin: 0 !important;
  }
`

export const LightCTitle = styled(CTitle)`
  font-family: Roboto, 'IBM Plex Sans', 'Libre Franklin', arial, sans-serif;
  margin-top: 0;
  font-weight: 400 !important;
`

export const NumeroCTitle = styled(CTitle)`
  font-family: Roboto, 'IBM Plex Sans', 'Libre Franklin', arial, sans-serif;
  margin: 0;
  text-align: center;
  display: block;
`

export const CDisplay = styled(Display)`
  font-family: Roboto, 'IBM Plex Sans', 'Libre Franklin', arial, sans-serif;
  margin: 1em 0 0;
  display: inline-block;
`
